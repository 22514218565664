.App-canvas {
	text-align: center;
    justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.App {
	background-color: white;
    width: 24rem;
	height: 100vh;
}

@media (max-width: 479px) /* and (min-height: 480px) */ {

	.App-canvas {
		background-color: white;
	}

	.App {
		font-size: 4vw;
	}
}

@media (min-width: 480px) /* and (min-height: 480px) */ {

	.App-canvas {
		background-color: antiquewhite;
	}

	.App {
		border-radius: 0.75rem;
		box-sizing: border-box;
		border-width: 0;
		display: block;
		--tw-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4), 0 0 10px 0 rgba(0, 0, 0, 0.1);
		box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	}
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		width: 130px;
		height: 50px;
	}
}

.App-Language {
	margin-top: 2px;
	margin-bottom: 20px;
}

.Receipt-Header {
	margin-bottom: 10px;
}

.Receipt-Container {
	width: 100%;
	border-radius: 0.3rem;
	box-sizing: border-box;
	border-width: 0;
	display: block;
	--tw-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	min-height: 40vh;
	padding: 1rem;
}

	.Receipt-Row {
		text-align: left;
		width: 100%;
		line-height: 20px;
	}
			
		.Receipt-Row-Header {
			width: 30%;
			font-weight: bold;
		}

		.Receipt-Row-Value {
			width: 70%;
		}

	@media (max-width: 479px) /* and (min-height: 480px) */ {

		.Receipt-Row {
			margin-bottom: 10px;
			height: 20px;
			/* margin-bottom: 15px;
			height: 50px; */
		}

			.Receipt-Row-Header {
				float: left;
				clear: left;
				/* width: 100%; */
			}

			.Receipt-Row-Value {
				float: left;
				/* width: 100%;
				margin-top: 3px;
				padding-left: 7px; */
			}
	}

	@media (min-width: 480px) /* and (min-height: 480px) */ {

		.Receipt-Row {
			margin-bottom: 10px;
			height: 20px;
		}

			.Receipt-Row-Header {
				float: left;
				clear: left;
			}

			.Receipt-Row-Value {
				float: left;
			}
	}

.Receipt-Buttons {
	margin-top: 15px;
}

	.Receipt-Download-PDF {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 15%;
		width: 70%;
		height: 30px;
		line-height: 30px;
		border-radius: 0.3rem;
		border: 1px solid black;
		background-color: #b81802;
		font-weight: bold;
		color: white;
		margin-bottom: 7px;
		cursor: pointer;
	}

	.Receipt-Download-PDF-disabled {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 15%;
		width: 70%;
		height: 30px;
		line-height: 30px;
		border-radius: 0.3rem;
		background-color: lightgray;
		font-weight: bold;
		color: white;
		margin-bottom: 7px;
		pointer-events: none;
		opacity: 0.4;
	}

	.Receipt-Download-Data {
		margin-left: 15%;
		width: 70%;
		line-height: 30px;
		border-radius: 0.3rem;
		border: 1px solid black;
		background-color: green;
		font-weight: bold;
		color: white;
		cursor: pointer;
	}

.pdf-container {
	margin-top: 20px;
	height: 65vh;
    overflow: scroll;
}

.pdf {
	display: flex;
	justify-content: center;
}	

.footer-center{
	position: absolute;
    bottom: 0;
    width: 24rem;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	padding: 1rem 0;
}

.pagination {
	display: flex;
    justify-content: space-around;
}

.loader-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 100px;
}

.loader {
	border: 10px solid lightgray;
	border-top: 10px solid #b81802;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 1s linear infinite;
	margin-left: .5rem;
  }

  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } 